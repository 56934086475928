import './App.scss'
import { Chat } from './Chat'
import { Url } from './types'
import { useLocalStorage } from './hooks/useStorage'

const backendBaseUrl =
  (process.env.REACT_APP_BACKEND_BASE_URL as Url | undefined) ??
  'http://localhost:3001'

function App() {
  const [userId, setUserId] = useLocalStorage<string | null>('userId', null)
  return <Chat baseUrl={backendBaseUrl} userId={userId} setUserId={setUserId} />
}

export default App
