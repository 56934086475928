import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  MainContainer,
  Message,
  MessageGroup,
  MessageInput,
  MessageList,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react'
import { MessageDirection } from '@chatscope/use-chat'
import { useCallback, useEffect, useState } from 'react'
import { useMounted } from './hooks/useMounted'
import wilburtAvatar from './wilburt.png'
import { ClientMessage, ServerData, Url } from './types'
import { useMediaQuery } from 'react-responsive'

type State =
  | {
      state: 'loading'
    }
  | {
      state: 'loadError'
      error: string
    }
  | {
      state: 'loaded'
      conversationId: string
      messages: ReadonlyArray<ClientMessage>
      canSend: boolean
      userId: string
      draft: string
      lastSendError: string | undefined
    }

const useChat = ({
  baseUrl,
  userId,
  setUserId,
}: {
  baseUrl: Url
  userId: string | null
  setUserId: (u: string | null) => void
}) => {
  const mounted = useMounted()
  const [state, setState] = useState<State>({ state: 'loading' })

  const isLoading = state.state === 'loading'
  useEffect(() => {
    if (isLoading) {
      fetch(`${baseUrl}/init`, {
        method: 'POST',
        credentials: 'include',
        headers: userId ? { 'x-wilburt-user-id': userId } : {},
      })
        .then(async (response) => {
          if (mounted.current) {
            if (!response.ok) {
              setState({
                state: 'loadError',
                error: `Received unexpected response from server: ${response.status}: ${response.statusText}`,
              })
            } else {
              try {
                const data: ServerData = await response.json()
                setUserId(data.userId)
                setState({
                  state: 'loaded',
                  conversationId: data.conversationId,
                  messages: data.messages.map((m) => ({
                    ...m,
                    status: 'seen',
                  })),
                  draft: '',
                  canSend: true,
                  userId: data.userId,
                  lastSendError: undefined,
                })
              } catch (e) {
                setState({
                  state: 'loadError',
                  error: `Error reading server response: ${e}`,
                })
              }
            }
          }
        })
        .catch((err: Error) => {
          setState({
            state: 'loadError',
            error: err?.message ?? 'An unknown error occurred.',
          })
        })
    }
  }, [isLoading, baseUrl, setUserId, userId, mounted])

  const lastSeenMessageId =
    state.state === 'loaded'
      ? state.messages[state.messages.length - 1]?.id
      : undefined
  const conversationId =
    state.state === 'loaded' ? state.conversationId : undefined
  const canSend = state.state === 'loaded' ? state.canSend : false

  const sendMessage = useCallback(
    async (input: string) => {
      if (state.state !== 'loaded' || !canSend || !userId) {
        return
      }

      setState((state) => ({ ...state, canSend: false }))
      fetch(`${baseUrl}/converse`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'x-wilburt-user-id': userId,
        },
        body: JSON.stringify({
          input,
          conversationId,
          lastSeenMessageId,
        }),
      })
        .then(async (response) => {
          if (mounted.current) {
            if (!response.ok) {
              setState((state) => ({
                ...state,
                canSend: true,
                lastSendError: `Network error: HTTP ${response.status}: ${response.statusText}`,
              }))
            } else {
              try {
                const json = await response.json()
                if (json.status === 'ok') {
                  setState((state) => ({
                    ...state,
                    canSend: true,
                    draft: '',
                    messages: [
                      ...(state.state === 'loaded' ? state.messages : []),
                      ...json.messages,
                    ],
                  }))
                } else {
                  setState((state) => ({
                    ...state,
                    canSend: true,
                    lastSendError: json.error,
                  }))
                }
              } catch (e) {
                setState((state) => ({
                  ...state,
                  canSend: true,
                  lastSendError: `Unable to process server response: ${e}`,
                }))
              }
            }
          }
        })
        .catch((e) => {
          if (mounted.current) {
            setState((state) => ({
              ...state,
              canSend: true,
              lastSendError: `Unexpected network error: ${e}`,
            }))
          }
        })
    },
    [state.state, canSend, conversationId, lastSeenMessageId, userId, baseUrl, mounted],
  )

  const setDraft = useCallback((draft: string) => {
    setState((state) => {
      if (state.state === 'loaded') {
        return {
          ...state,
          draft,
        }
      }
      return state
    })
  }, [])

  return state.state === 'loaded'
    ? {
        ...state,
        sendMessage,
        setDraft,
      }
    : state
}

interface LiteMessageGroup {
  key: string
  sender: 'AI' | 'HUMAN'
  messages: ReadonlyArray<ClientMessage>
}

function* messagesToMessageGroups(
  messages: ReadonlyArray<ClientMessage>,
): Generator<LiteMessageGroup> {
  for (let i = 0; i < messages.length; ) {
    let key = messages[i].id
    let sender = messages[i].sender
    let j = i + 1

    for (; j < messages.length; ++j) {
      if (messages[j].sender !== sender) {
        break
      }
    }
    yield {
      key,
      sender,
      messages: messages.slice(i, j),
    }

    i = j
  }
}

function senderToDirection(sender: string) {
  if (sender === 'AI') {
    return MessageDirection.Incoming
  }
  return MessageDirection.Outgoing
}

export function Chat(props: {
  baseUrl: Url
  userId: string | null
  setUserId: (u: string | null) => void
}) {
  const chat = useChat({
    baseUrl: props.baseUrl,
    userId: props.userId,
    setUserId: props.setUserId,
  })
  const onMobileDevice = useMediaQuery({ query: '(hover: none)' })

  const typingIndicator =
    chat.state === 'loaded' && !chat.canSend ? (
      <TypingIndicator content={`Wilburt is thinking`} />
    ) : undefined

  return (
    <MainContainer>
      <ChatContainer>
        <ConversationHeader>
          <Avatar src={wilburtAvatar} />
          <ConversationHeader.Content userName={'Wilburt'} />
        </ConversationHeader>
        <MessageList
          typingIndicator={typingIndicator}
          loading={chat.state === 'loading'}
        >
          {chat.state === 'loaded' ? (
            <>
              {Array.from(messagesToMessageGroups(chat.messages)).map(
                (messageGroup) => (
                  <MessageGroup
                    key={messageGroup.key}
                    direction={senderToDirection(messageGroup.sender)}
                  >
                    <MessageGroup.Messages>
                      {messageGroup.messages.map((message) => {
                        return (
                          <Message
                            key={message.id}
                            model={{
                              type:
                                message.contentType === 'html'
                                  ? 'html'
                                  : 'text',
                              payload: message.content,
                              position: 'single',
                              direction: senderToDirection(message.sender),
                            }}
                          />
                        )
                      })}
                    </MessageGroup.Messages>
                  </MessageGroup>
                ),
              )}
              {chat.lastSendError ? (
                <Message type="custom" className="error">
                  <Message.CustomContent>
                    Error sending last message: {chat.lastSendError}
                  </Message.CustomContent>
                </Message>
              ) : null}
            </>
          ) : chat.state === 'loadError' ? (
            <MessageList.Content
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                fontSize: '1.2em',
                color: 'black',
              }}
            >
              {chat.error ? `Sorry! An error occurred: ${chat.error}` : "Sorry! An error occurred."}
            </MessageList.Content>
          ) : null}
        </MessageList>

        <MessageInput
          disabled={chat.state !== 'loaded' || !chat.canSend}
          value={chat.state === 'loaded' ? chat.draft : ''}
          onSend={(htmlContent, textContent, innerText) =>
            chat.state === 'loaded' &&
            chat.sendMessage(innerText.replace(/\n\n/g, '\n'))
          }
          onChange={(innerHtml) =>
            chat.state === 'loaded' ? chat.setDraft(innerHtml) : undefined
          }
          attachButton={false}
          sendOnReturnDisabled={onMobileDevice}
          autoFocus={true}
        />
      </ChatContainer>
    </MainContainer>
  )
}
