import { useCallback, useState, useEffect } from 'react'

type JSONable =
  | string
  | number
  | null
  | boolean
  | Array<JSONable>
  | { [k: string]: JSONable }

type UseStorageRet<T> = [T, (v: T | ((v: T) => T)) => void, () => void]
type Default<T> = T | (() => T)

export function useLocalStorage<T extends JSONable>(
  key: string,
  defaultValue: Default<T>,
): UseStorageRet<T> {
  return useStorage(key, defaultValue, window.localStorage)
}
export function useSessionStorage<T extends JSONable>(
  key: string,
  defaultValue: Default<T>,
): UseStorageRet<T> {
  return useStorage(key, defaultValue, window.sessionStorage)
}

interface StorageObject {
  setItem(key: string, value: string): void
  getItem(key: string): string | null
  removeItem(key: string): void
}

const enum Source {
  Default,
  Storage,
}

function useStorage<T extends JSONable>(
  key: string,
  defaultValue: Default<T>,
  storageObject: StorageObject,
): UseStorageRet<T> {
  const [value, setValue] = useState<{ value: T; source: Source }>(() => {
    const jsonValue = storageObject.getItem(key)
    if (jsonValue !== null) {
      return { value: JSON.parse(jsonValue), source: Source.Storage }
    }
    if (typeof defaultValue === 'function') {
      return { value: defaultValue(), source: Source.Default }
    } else {
      return { value: defaultValue, source: Source.Default }
    }
  })

  useEffect(() => {
    if (value.source !== Source.Default) {
      storageObject.setItem(key, JSON.stringify(value.value))
    }
  }, [key, value.value, value.source, storageObject])

  const remove = useCallback(() => {
    storageObject.removeItem(key)
    if (typeof defaultValue === 'function') {
      setValue({ value: defaultValue(), source: Source.Default })
    } else {
      setValue({ value: defaultValue, source: Source.Default })
    }
  }, [storageObject, defaultValue, key])

  const _setValue = useCallback((v: T | ((v: T) => T)) => {
    setValue({
      value: typeof v === 'function' ? v(value.value) : v,
      source: Source.Storage,
    })
  }, [value.value])

  return [value.value, _setValue, remove]
}
